import { addMargin, introductionDesc } from "../util/vendors";
import Siema from "siema";

export default {
	init() {

		//MAPA

		const setHeightPlace = () => {
			const contactPlace = document.querySelector(".contact__places");
			const contactPlaces = document.querySelectorAll(".contact__place")[0];
			const placeChildren = contactPlaces.children;
			let child1;
			let child2;

			for (let i = 0; i < placeChildren.length; i++) {
				child1 = placeChildren[0].offsetHeight;
				child2 = placeChildren[1].offsetHeight;
			}

			contactPlace.style.height = `${child1 + child2}px`;

			if (window.innerWidth > 480) {
				contactPlace.style.height = "auto";
			}
		};

		window.addEventListener("resize", setHeightPlace);

		if (window.innerWidth > 768) {
			addMargin(introductionDesc);
		} else {
			setHeightPlace();
		}

		const sectorsBtns = document.querySelectorAll("[data-sector-btn]");

		const sectorsActive = (e) => {
			e.preventDefault();

			for (var i = 0; i < sectorsBtns.length; i++) {
				sectorsBtns[i].classList.remove("contact__btn--active");
			}

			const clickedTab = e.currentTarget;
			clickedTab.classList.add("contact__btn--active");
			const sectorsTabs = document.querySelectorAll("[data-sector]");

			for (i = 0; i < sectorsTabs.length; i++) {
				sectorsTabs[i].classList.remove("contact__details--active");
			}

			const elementReference = e.currentTarget;
			const activeTabID = elementReference.getAttribute("data-sector-btn");
			const activeTab = document.querySelector(`[data-sector="${activeTabID}"`);
			const heightElement = activeTab.offsetHeight;
			contactDetail.style.height = `${heightElement}px`;
			activeTab.classList.add("contact__details--active");
		};

		for (let i = 0; i < sectorsBtns.length; i++) {
			sectorsBtns[i].addEventListener("click", sectorsActive)
		}

		const placesBtns = document.querySelectorAll("[data-place-btn]");

		const placesActive = (e) => {
			e.preventDefault();

			for (let i = 0; i < placesBtns.length; i++) {
				placesBtns[i].classList.remove("contact__btn--select");
			}

			const clickedTab = e.currentTarget;
			clickedTab.classList.add("contact__btn--select");

			const placesTabs = document.querySelectorAll("[data-place-details]");

			const elementReference = e.currentTarget;
			const activeTabID = elementReference.getAttribute("data-place-btn");

			for (i = 0; i < placesTabs.length; i++) {
				placesTabs[i].classList.remove("contact__place--active");
			}

			const activeTab = document.querySelector(`[data-place-details="${activeTabID}"`);
			activeTab.classList.add("contact__place--active");

			const setMarker = (latitude, longitude) => {
				marker = new google.maps.Marker({
					position: new google.maps.LatLng(latitude, longitude),
					map: map,
					icon: `${window.location.origin}/wp-content/themes/trzuskawica/src/images/marker.svg`
				});
			}

			switch (e.currentTarget.getAttribute("data-place-btn")) {
				case "Sitkówka":
					map.setCenter(new google.maps.LatLng(50.8061501, 20.5580461));
					setMarker(50.8061501, 20.5580461);
					break;
				case "Targowica":
					map.setCenter(new google.maps.LatLng(50.6889921, 16.9641882));
					setMarker(50.6889921, 16.9641882);
					break;
			}
		};

		for (var i = 0; i < placesBtns.length; i++) {
			placesBtns[i].addEventListener("click", placesActive)
		}

		const formLabels = document.querySelectorAll(".form-element");

		const removeTitle = () => {
			formLabels.forEach((label) => {
				label.addEventListener("blur", (e) => {
					e.currentTarget.parentElement.previousElementSibling.classList.remove("animate");
				});
			});
		};

		removeTitle();

		const animateLabel = () => {
			formLabels.forEach((label) => {
				label.setAttribute("autocomplete", "off");
				label.addEventListener("focus", (e) => {
					e.currentTarget.parentElement.previousElementSibling.classList.add("animate");
				});
			});
		};

		animateLabel();

		/* Kontakt */

		// const directSlider = new Siema({
		// 	selector: "#directSlider",
		// 	duration: 200,
		// 	easing: "ease-out",
		// 	perPage: {
		// 		1920: 4.5,
		// 		1440: 3.5,
		// 		550: 2,
		// 		400: 1
		// 	},
		// 	startIndex: 0,
		// 	draggable: true,
		// 	multipleDrag: false,
		// 	threshold: 20,
		// 	loop: window.innerWidth <= 1360,
		// 	rtl: false,
		// 	onInit: function() {},
		// 	onChange: function() {
		//
		// 		const dots = document.querySelectorAll(".dots--contact .dots__item");
		//
		// 		dots.forEach((slide, i) => {
		// 			let addOrRemove = i === this.currentSlide ? "add" : "remove";
		// 			dots[i].classList[addOrRemove]("dots__item--active");
		// 		});
		// 	}
		// });

		// Siema.prototype.contactDots = function(element) {
		// 	for (let i = 0; i < this.innerElements.length; i++) {
		// 		const navWrapper = document.querySelector(`.${element}`);
		// 		const btn = document.createElement("button");
		//
		// 		btn.classList.add("dots__item");
		// 		btn.addEventListener("click", () => this.goTo(i));
		//
		// 		navWrapper.appendChild(btn);
		//
		// 		const dots = Array.prototype.slice.call(document.querySelectorAll(".dots__item"));
		// 		dots[0].classList.add("dots__item--active");
		// 	}
		// };
		//
		// directSlider.contactDots("dots--contact");
		//
		// const prevSlide = () => {
		// 	directSlider.prev();
		// };
		//
		// const nextSlide = () => {
		// 	directSlider.next();
		// };
		//
		// document.querySelector(".slider__btn--prev").addEventListener("click", prevSlide);
		// document.querySelector(".slider__btn--next").addEventListener("click", nextSlide);

	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	},
};